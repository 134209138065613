import { default as index7KgSZPrKaUMeta } from "/var/www/dev/oxia-website/pages/admin/index.vue?macro=true";
import { default as loginOMINsNFbUOMeta } from "/var/www/dev/oxia-website/pages/admin/login.vue?macro=true";
import { default as book_45your_45appointmentHatI6J9aiPMeta } from "/var/www/dev/oxia-website/pages/book-your-appointment.vue?macro=true";
import { default as employee_45wellnessnXJ2PKQnbuMeta } from "/var/www/dev/oxia-website/pages/employee-wellness.vue?macro=true";
import { default as general_45practitioner_45pageE6zGwwWtfkMeta } from "/var/www/dev/oxia-website/pages/general-practitioner-page.vue?macro=true";
import { default as independent_45screening_45centreoVvXstENIsMeta } from "/var/www/dev/oxia-website/pages/independent-screening-centre.vue?macro=true";
import { default as indexkrxdGonSNRMeta } from "/var/www/dev/oxia-website/pages/index.vue?macro=true";
import { default as ophthalmologist_45pageB1CbvbE6BrMeta } from "/var/www/dev/oxia-website/pages/ophthalmologist-page.vue?macro=true";
import { default as optometrist_45more_45infoF1D9YjSKURMeta } from "/var/www/dev/oxia-website/pages/optometrist-more-info.vue?macro=true";
import { default as other_45pageQqzqeTn8xoMeta } from "/var/www/dev/oxia-website/pages/other-page.vue?macro=true";
import { default as patient_45more_45infooaNhDwewlMMeta } from "/var/www/dev/oxia-website/pages/patient-more-info.vue?macro=true";
import { default as pharmacy_45pagezRO3CiCxKhMeta } from "/var/www/dev/oxia-website/pages/pharmacy-page.vue?macro=true";
import { default as physician_45pagejhGJd8FzfkMeta } from "/var/www/dev/oxia-website/pages/physician-page.vue?macro=true";
import { default as questions_45and_45answersAx1zQPCwxMMeta } from "/var/www/dev/oxia-website/pages/questions-and-answers.vue?macro=true";
import { default as registration_45completion_45pageEtUQOTQLwfMeta } from "/var/www/dev/oxia-website/pages/registration-completion-page.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/var/www/dev/oxia-website/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login",
    path: "/admin/login",
    component: () => import("/var/www/dev/oxia-website/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "book-your-appointment",
    path: "/book-your-appointment",
    component: () => import("/var/www/dev/oxia-website/pages/book-your-appointment.vue").then(m => m.default || m)
  },
  {
    name: "employee-wellness",
    path: "/employee-wellness",
    component: () => import("/var/www/dev/oxia-website/pages/employee-wellness.vue").then(m => m.default || m)
  },
  {
    name: "general-practitioner-page",
    path: "/general-practitioner-page",
    component: () => import("/var/www/dev/oxia-website/pages/general-practitioner-page.vue").then(m => m.default || m)
  },
  {
    name: "independent-screening-centre",
    path: "/independent-screening-centre",
    component: () => import("/var/www/dev/oxia-website/pages/independent-screening-centre.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/dev/oxia-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ophthalmologist-page",
    path: "/ophthalmologist-page",
    component: () => import("/var/www/dev/oxia-website/pages/ophthalmologist-page.vue").then(m => m.default || m)
  },
  {
    name: "optometrist-more-info",
    path: "/optometrist-more-info",
    component: () => import("/var/www/dev/oxia-website/pages/optometrist-more-info.vue").then(m => m.default || m)
  },
  {
    name: "other-page",
    path: "/other-page",
    component: () => import("/var/www/dev/oxia-website/pages/other-page.vue").then(m => m.default || m)
  },
  {
    name: "patient-more-info",
    path: "/patient-more-info",
    component: () => import("/var/www/dev/oxia-website/pages/patient-more-info.vue").then(m => m.default || m)
  },
  {
    name: "pharmacy-page",
    path: "/pharmacy-page",
    component: () => import("/var/www/dev/oxia-website/pages/pharmacy-page.vue").then(m => m.default || m)
  },
  {
    name: "physician-page",
    path: "/physician-page",
    component: () => import("/var/www/dev/oxia-website/pages/physician-page.vue").then(m => m.default || m)
  },
  {
    name: "questions-and-answers",
    path: "/questions-and-answers",
    component: () => import("/var/www/dev/oxia-website/pages/questions-and-answers.vue").then(m => m.default || m)
  },
  {
    name: "registration-completion-page",
    path: "/registration-completion-page",
    component: () => import("/var/www/dev/oxia-website/pages/registration-completion-page.vue").then(m => m.default || m)
  }
]