<template>
  <div class="top-bar">
    <div class="hamburger-menu-wrapper" :class="{ 'is-expanded': menuOpen }">
      <div class="hamburger-menu-container" :class="{ 'menu-open': menuOpen }" >
        <input type="checkbox" id="hamburger-menu-input" v-model="menuOpen" >
        <label class="hamburger-menu-icon" for="hamburger-menu-input" @click="toggleMenu">
          <div></div>
          <div></div>
          <div></div>
        </label>

        <nav class="hamburger-menu-items" :class="{ 'is-open': menuOpen }">
          <ul class="hamburger-menu-items-list">
            <NuxtLink to="/" class="top-bar-logo-link">
              <div class="hamburger-menu-logo-container">
                <img src="../images/OxiaLogoAllWhite.png" alt="Logo" class="hamburger-menu-logo" @click="closeMenu" />
              </div>
            </NuxtLink>
            <li class="hamburger-menu-items-list-item"><a @click="router.push('patient-more-info'); closeMenu()">Patient</a></li>
            <li class="hamburger-menu-items-list-item has-nested-list">
              <input type="checkbox" class="nested-list-dropdown-checkbox" id="professionals-checkbox">
              <label class="nested-list-dropdown-label" for="professionals-checkbox">
                <span>Professionals</span>
                <div class="os-icon icon-down"></div>
              </label>
              <ul class="nested-list">
                <li><button @click="router.push('ophthalmologist-page'); closeMenu()">Ophthalmologist</button></li>
                <li><button @click="router.push('physician-page'); closeMenu()">Physician</button></li>
                <li><button @click="router.push('general-practitioner-page'); closeMenu()">General Practitioner</button></li>
                <li><button @click="router.push('optometrist-more-info'); closeMenu()">Optometrist</button></li>
                <li><button @click="router.push('pharmacy-page'); closeMenu()">Pharmacy</button></li>
                <li><button @click="router.push('independent-screening-centre'); closeMenu()">Independent Screening Centre</button></li>
                <li><button @click="router.push('other-page'); closeMenu()">Other</button></li>
              </ul>
            </li>
            <li class="hamburger-menu-items-list-item"><a @click="router.push('employee-wellness'); closeMenu()">Employee Wellness</a></li>
            <li class="hamburger-menu-items-list-item"><a @click="router.push('questions-and-answers'); closeMenu()">Q&A</a></li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="top-bar-logo-wrapper">
      <NuxtLink to="/" class="top-bar-logo-link">
        <img src="/images/TopBarLogo.png" class="top-bar-logo" />
        <!-- <span class="top-bar-logo-prevent-now">SCREEN SMART LIVE SMART</span> -->
      </NuxtLink>
    </div>
  </div>
</template>


<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const scrollingDown = ref(false);
const atTop = ref(true);
const menuOpen = ref(false);

watch(() => router.currentRoute.value.path, (newPath) => {
  scrollingDown.value = newPath === 'index';
});

const closeMenu = () => {
  menuOpen.value = false;
};

const lastScrollY = ref(0);
</script>
